<template>
  <div class="g-search" :class="{ 'g-search--active': isActive }" v-click-outside="onClickOutside">
    <div class="g-search__input-wrapper">
      <input class="g-search__input" type="text" :value="value" @input="onSearch">
    </div>
    <div class="g-search__icon" @click="isActive = !isActive">
      <Search />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalSearch',

  props: {
    value: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  components: {
    Search: () => import('@/components/icons/Search')
  },

  methods: {
    onSearch (e) {
      if (this.$route.query.all === undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.$router.push({
          path: '/',
          query: {
            all: null
          }
        })?.catch(() => {})
      }
      this.$emit('input', e.target.value)
    },

    onClickOutside () {
      this.isActive = false
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/elements/search';
</style>
